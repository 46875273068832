import * as React from "react"
import Layout from '../components/Layout'
import Section from '../components/Section'
import { StaticImage } from "gatsby-plugin-image"

const SnakesPage = () => {
  
	return (
		<Layout pageTitle="A book: Snakes Dont Eat Carrots">
			<Section id="book-intro" background="light">
				<div className="flex small-image">
					<div className="image-wrapper">
						<StaticImage
						src="../images/snakes-dont-eat-carrots-cover.png"
						alt="Cover of the book Snakes Don't Eat Carrots by Richard Weeks"
						placeholder="blurred"
						/>
					</div>
					<div className="content-wrapper">
						<h2>Snakes Don&rsquo;t Eat Carrots</h2>
						<p><strong>Published: 2015</strong></p>
						<p>Snakes Don&rsquo;t Eat Carrots is a captivating portrait of persistence and passion. With grace, wry wit, and clear-eyed vision, the author is able to take the reader through formative moments in his childhood and revealing experiences as an adult. With each story, we see how a scientist sees the world and learn how a writer comes to understand the scope of life.</p>
					</div>
				</div>
			</Section>
	    </Layout>
	)
}

export default SnakesPage